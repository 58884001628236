import { gql, useMutation } from "@apollo/client";
import { SNACK_DURATION } from "Common/helpers";
import { concatAllErrors } from "Common/helpers";
import { ERROR_MESSAGE } from "Common/helpers";
import { success_options } from "Common/helpers";
import { error_options } from "Common/helpers";
import React, { useRef, useState, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useSnackbar } from "react-simple-snackbar";

const AudioRecorder = ({
  audioUrl,
  inquiryId,
  defaultNotes,
  reloadData = null,
  status = null,
}) => {
  const [recordedUrl, setRecordedUrl] = useState(audioUrl || "");
  const [isRecording, setIsRecording] = useState(false);
  const mediaStream = useRef(null);
  const mediaRecorder = useRef(null);
  const chunks = useRef([]);
  const [openSnackbarError] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const [notes, setNotes] = useState(defaultNotes || "");
  const [disableNotes, setDisableNotes] = useState(true);
  const [newRecording, setNewRecording] = useState(false);
  const [isMicAccessible, setIsMicAccessible] = useState(null);

  useEffect(() => {
    // Check if the browser supports `navigator.mediaDevices.getUserMedia`
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      setIsMicAccessible(false);
      return;
    }

    // Try to request microphone access
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        setIsMicAccessible(true); // Access granted
        stream.getTracks().forEach((track) => track.stop()); // Stop the tracks after checking
      })
      .catch((err) => {
        console.error("Microphone access denied:", err);
        setIsMicAccessible(false); // Access denied
      });
  }, []);

  const UPLOAD_VOICE = gql`
    mutation uploadPatientVoiceNote($id: Int!, $file: Upload!) {
      uploadPatientVoiceNote(id: $id, file: $file) {
        obj {
          id
        }
      }
    }
  `;

  // pass in the UPLOAD_MUTATION mutation we created earlier.
  const [uploadVoice, { loading }] = useMutation(UPLOAD_VOICE, {
    onCompleted({ uploadPatientVoiceNote }) {
      if (
        uploadPatientVoiceNote?.errors &&
        uploadPatientVoiceNote?.errors?.length > 0
      ) {
        // let error_messages_string = getErrorMessage(patientFileUpload?.errors);
        openSnackbarError(uploadPatientVoiceNote?.errors, [SNACK_DURATION]);
      } else {
        setNewRecording(false);
        openSnackbarSuccess("File uploaded");
        reloadData?.();
      }
    },
    onError: (e) => {
      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbarError(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const UPDATE_NOTE = gql`
    mutation updateInquiryNote($id: Int!, $notes: String!) {
      updateInquiryNote(id: $id, notes: $notes) {
        obj {
          id
        }
      }
    }
  `;

  // pass in the UPLOAD_MUTATION mutation we created earlier.
  const [updateNotes, { loading: noteLoading }] = useMutation(UPDATE_NOTE, {
    onCompleted({ updateInquiryNote }) {
      if (updateInquiryNote?.errors && updateInquiryNote?.errors?.length > 0) {
        // let error_messages_string = getErrorMessage(patientFileUpload?.errors);
        openSnackbarError(updateInquiryNote?.errors, [SNACK_DURATION]);
      } else {
        openSnackbarSuccess("Notes updated.");
        reloadData?.();
      }
    },
    onError: (e) => {
      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbarError(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const SEND_TO_PATIENT = gql`
    mutation sendInquiryToPatient($id: Int!) {
      sendInquiryToPatient(id: $id) {
        obj {
          id
        }
      }
    }
  `;

  // pass in the UPLOAD_MUTATION mutation we created earlier.
  const [sentToPatient, { loading: sendLoading }] = useMutation(
    SEND_TO_PATIENT,
    {
      onCompleted({ sendInquiryToPatient }) {
        if (
          sendInquiryToPatient?.errors &&
          sendInquiryToPatient?.errors?.length > 0
        ) {
          // let error_messages_string = getErrorMessage(patientFileUpload?.errors);
          openSnackbarError(sendInquiryToPatient?.errors, [SNACK_DURATION]);
        } else {
          openSnackbarSuccess("Successfully sent.");
          reloadData?.();
        }
      },
      onError: (e) => {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbarError(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const startRecording = async () => {
    try {
      setRecordedUrl(""); // Clear previous recording URL if any
      setIsRecording(true); // Set recording state to true
      setNewRecording(true);

      // Access the microphone and start recording
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStream.current = stream;
      mediaRecorder.current = new MediaRecorder(stream);

      // Collect audio data chunks
      mediaRecorder.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.current.push(e.data);
        }
      };

      // When recording stops, create a Blob URL for playback
      mediaRecorder.current.onstop = () => {
        const recordedBlob = new Blob(chunks.current, { type: "audio/webm" });
        const url = URL.createObjectURL(recordedBlob);
        setRecordedUrl(url);
        chunks.current = []; // Clear chunks
        setIsRecording(false); // Reset recording state
      };

      // Start recording
      mediaRecorder.current.start();
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.stop();
    }
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => {
        track.stop();
      });
    }
    setIsRecording(false); // Reset recording state
  };

  const saveRecording = async () => {
    if (!recordedUrl) return;

    try {
      // Fetch the Blob from the recorded URL
      const response = await fetch(recordedUrl);
      const audioBlob = await response.blob();

      // Convert Blob to a File with an appropriate name and MIME type
      const audioFile = new File([audioBlob], "recording.mp3", {
        type: "audio/mpeg",
      });

      const payload = {
        id: inquiryId,
        file: audioFile, // Use audioFile for correct filename and type
      };

      await uploadVoice({ variables: payload });
    } catch (error) {
      console.error("Error uploading recording:", error);
      openSnackbarError("Error uploading recording.", SNACK_DURATION);
    }
  };

  const saveNotes = async () => {
    if (!notes) return;

    try {
      const payload = {
        id: inquiryId,
        notes: notes, // Use audioFile for correct filename and type
      };

      await updateNotes({ variables: payload });
      setDisableNotes(true);
    } catch (error) {
      openSnackbarError("Error updating notes.", SNACK_DURATION);
    }
  };

  const sendToPatient = async () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to send this to patient?"
    );
    if (!userConfirmed) {
      return;
    }

    try {
      const payload = {
        id: inquiryId,
      };

      await sentToPatient({ variables: payload });
    } catch (error) {
      openSnackbarError("Error updating notes.", SNACK_DURATION);
    }
  };

  return (
    <>
      <div>
        {/* Only show audio component if there's a recording URL */}
        <div class="d-flex align-items-center ">
          {recordedUrl && (
            <div>
              <audio controls src={recordedUrl} type="audio/ogg; codecs=opus" />
            </div>
          )}

          {/* Toggle between Start and Stop buttons based on recording state */}
          {!isMicAccessible ? (
            <div className="alert alert-danger text-center">
              <strong>Warning:</strong> Your browser does not support microphone
              access or permission is not enabled. Please enable microphone
              access in your browser settings.
            </div>
          ) : isRecording ? (
            <div className="d-flex">
              <Spinner animation="grow" variant="success" />
              <button
                className="btn btn-outline-primary"
                onClick={stopRecording}
              >
                <i className="fa fa-microphone-slash" aria-hidden="true"></i>
              </button>
            </div>
          ) : (
            status !== "COMPLETED" && (
              <button
                className="btn btn-outline-primary"
                onClick={startRecording}
              >
                <i className="fa fa-microphone" aria-hidden="true"></i>
              </button>
            )
          )}
        </div>

        {/* Show Save button only if there's a recording URL */}
        {recordedUrl && newRecording && status !== "COMPLETED" && (
          <button
            className="btn btn-primary ml-2"
            onClick={saveRecording}
            disabled={loading}
          >
            Save Recording
          </button>
        )}
        <hr />
        <p>
          Add notes
          {status !== "COMPLETED" && (
            <button
              className="btn btn-outline-info ml-2"
              onClick={() => setDisableNotes(!disableNotes)}
            >
              <i className="fa fa-edit" aria-hidden="true"></i>
            </button>
          )}
        </p>
        <Form.Control
          autoComplete="off"
          as="textarea"
          rows={5}
          name="notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          disabled={disableNotes}
        />
        {!disableNotes && (
          <button
            className="btn btn-primary mt-2"
            onClick={saveNotes}
            disabled={noteLoading}
          >
            Save Notes
          </button>
        )}

        {disableNotes && status !== "COMPLETED" && (
          <button
            className="btn btn-success my-2 float-right"
            onClick={sendToPatient}
            disabled={sendLoading}
          >
            Send this to patient
          </button>
        )}
      </div>
    </>
  );
};

export default AudioRecorder;
