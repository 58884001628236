import React, { useState, useCallback, useRef, useEffect } from "react";
import { Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { useSnackbar } from "react-simple-snackbar";
import {
  success_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  error_options,
  concatAllErrors,
} from "../Common/helpers";
import { gql, useMutation } from "@apollo/client";
import { useDropzone } from "react-dropzone";
import { Formik } from "formik";
// import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/browser";

function PatientFileUploadModal(props) {
  const {
    setShowModal,
    patient_id,
    encounter_id,
    setAllFiles,
    REQUEST_PATIENT,
  } = props;
  const [selectedFile, setSelectedFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formikRef = useRef();
  const [openSnackbarSuccess] = useSnackbar(success_options);
  // const { t  } = useTranslation();
  const [openSnackbarError] = useSnackbar(error_options);

  const UPLOAD_FILE_MUTATION = gql`
    mutation patientFileUpload(
      $file: Upload!
      $patient: ID!
      $name: String!
      $description: String
    ) {
      patientFileUpload(
        file: $file
        patient: $patient
        name: $name
        description: $description
      ) {
        obj {
          files {
            edges {
              node {
                name
                id
                file
                created
                description
              }
            }
          }
        }
      }
    }
  `;

  // pass in the UPLOAD_MUTATION mutation we created earlier.
  const [patientFileUpload] = useMutation(UPLOAD_FILE_MUTATION, {
    onCompleted({ patientFileUpload }) {
      if (patientFileUpload?.errors && patientFileUpload?.errors?.length > 0) {
        // let error_messages_string = getErrorMessage(patientFileUpload?.errors);
        openSnackbarError(patientFileUpload?.errors, [SNACK_DURATION]);
      } else {
        console.log("updateLabFile", patientFileUpload);
        openSnackbarSuccess("File uploaded");
        setShowModal(false);
        setIsSubmitting(false);
        if (setAllFiles) {
          setAllFiles(patientFileUpload.obj.files.edges);
        }
      }
    },
    refetchQueries: [{ query: REQUEST_PATIENT, fetchPolicy: "network-only" }],
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      console.log("eeeee", e);
      Sentry.captureException("UPLOAD_FILE_MUTATION error " + e);

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbarError(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const onSubmit = (values, { resetForm }) => {
    setIsSubmitting(true);
    console.log("values", values);
    console.log("selectedFile", selectedFile);
    if (selectedFile) {
      if (values.fileName) {
        let val = {
          file: selectedFile,
          patient: patient_id,
          name: values.fileName,
          description: values.fileDescription,
        };
        patientFileUpload({
          // use the variables option so that you can pass in the file we got above
          variables: val,
        });
        console.log("vallll", val);
      } else {
        openSnackbarError("Please fill all required information.", [
          SNACK_DURATION,
        ]);
      }
    } else {
      openSnackbarError("Please select a file to upload.", [SNACK_DURATION]);
    }
  };
  const onDrop = useCallback(
    (acceptedFiles) => {
      // select the first file from the Array of files
      const files = acceptedFiles[0];
      if (files) {
        setSelectedFile(files);
      } else {
        console.log("NO File");
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [patientFileUpload]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const initialValues = {
    name: "",
    description: "",
  };
  console.log("selectedFile", selectedFile);
  return (
    <>
      <Row className=" p-4 ">
        <Col md={12} lg={12} className="">
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            innerRef={formikRef}
          >
            {({ handleSubmit, handleChange, handleReset, values }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row>
                  <Col md={6} lg={6} xl={6} sm={12}>
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm={4}
                        md={3}
                        className="text-right pr-0 required"
                      >
                        <span className="">File</span>
                      </Form.Label>
                      <Col sm={8} md={9}>
                        {
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Button variant="link">
                              {selectedFile ? selectedFile.name : "Upload File"}{" "}
                            </Button>
                          </div>
                        }
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={6} lg={6} xl={6} sm={12}>
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm={4}
                        md={4}
                        className="text-right pr-0 required"
                      >
                        <span className="">File Name</span>
                      </Form.Label>
                      <Col sm={8} md={8}>
                        <Form.Control
                          autoComplete="off"
                          as="input"
                          type="input"
                          name="fileName"
                          value={values.fileName}
                          onChange={handleChange}
                          required
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={12} lg={12} xl={12} sm={12}>
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm={4}
                        md={3}
                        className="text-right pr-0"
                      >
                        <span className="">File Description</span>
                      </Form.Label>
                      <Col sm={8} md={9}>
                        <Form.Control
                          autoComplete="off"
                          as="input"
                          type="input"
                          name="fileDescription"
                          value={values.fileDescription}
                          onChange={handleChange}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={12} lg={12} xl={12} sm={12}>
                    <div className="d-flex justify-content-end submitPayment">
                      <Button
                        type="button"
                        variant="primary"
                        className="mx-3"
                        onClick={() => setShowModal(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="sm"
                        onClick={() => (isSubmitting ? null : handleSubmit())}
                      >
                        {" "}
                        {isSubmitting ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );
}
export default PatientFileUploadModal;
