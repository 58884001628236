import React from "react";
// import style from './Navigation.module.css';
import { Col, Row, Button, Dropdown, Badge } from "react-bootstrap";
import LogoutButton from "./LogoutButton/LogoutButton";
import { withRouter, Link } from "react-router-dom";
import Logo from "../../assets/images/img.png";
import { gql, useQuery } from "@apollo/client";
import { getPermissionForAction } from "../../Common/helpers";

const Navigation = (props) => {
  const USER_DETAILS = gql`
    query receiveDate {
      userDetails @client
    }
  `;

  const { data } = useQuery(USER_DETAILS);

  var userDetails = data.userDetails;
  if (userDetails && userDetails.indexOf("username") > -1) {
    userDetails = JSON.parse(userDetails);
  }

  const getDate = () => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    let today = new Date();
    let res = days[today.getDay()];
    res += " - " + monthNames[today.getMonth()];
    res += " " + today.getDate();
    res += ", " + today.getFullYear();
    return res;
  };

  // const PRESCRIPTION_REQUESTS = gql`
  //   query {
  //     patientPrescriptionRequests(status: "pending",) {
  //       totalCount
  //     }
  //   }
  // `;

  const PRESCRIPTION_REQUESTS = gql`
    query PatientPrescriptionRequests(
      $status: String!
      $doctor_Identifier: String
    ) {
      patientPrescriptionRequests(
        status: $status
        doctor_Identifier: $doctor_Identifier
      ) {
        totalCount
      }
    }
  `;

  const { data: prescriptionRequests = [] } = useQuery(PRESCRIPTION_REQUESTS, {
    variables: {
      status: "pending",
      doctor_Identifier: userDetails.doctor?.identifier || null, // Pass doctor_Identifier if it exists
    },
    fetchPolicy: "network-only",
    pollInterval: 5 * 60 * 1000, //5 mins
    onError: (err) => {},
  });

  const MEDICAL_REQUEST = gql`
    query patientMedicalRequests(
      $status: String
      $doctor_Identifier: String
      $currentStatus: String
    ) {
      patientMedicalRequests(
        status: $status
        doctor_Identifier: $doctor_Identifier
        currentStatus: $currentStatus
      ) {
        totalCount
      }
    }
  `;

  const { data: newMedical = [] } = useQuery(MEDICAL_REQUEST, {
    variables: {
      status: "pending",
      doctor_Identifier: userDetails.doctor?.identifier || null, // Pass doctor_Identifier if it exists
    },
    fetchPolicy: "network-only",
    pollInterval: 5 * 60 * 1000, //5 mins
    onError: (err) => {},
  });

  const newMedicalCount = newMedical?.patientMedicalRequests?.totalCount || 0;

  const { data: forwardedMedical = [] } = useQuery(MEDICAL_REQUEST, {
    variables: {
      currentStatus: "forwardedtome",
    },
    fetchPolicy: "network-only",
    pollInterval: 5 * 60 * 1000, //5 mins
    onError: (err) => {},
  });

  const forwardedMedicalCount =
    forwardedMedical?.patientMedicalRequests?.totalCount || 0;

  const { data: forApprovalMedical = [] } = useQuery(MEDICAL_REQUEST, {
    variables: {
      currentStatus: "forapproval",
    },
    fetchPolicy: "network-only",
    pollInterval: 5 * 60 * 1000, //5 mins
    onError: (err) => {},
  });

  const forApprovalMedicalCount =
    forApprovalMedical?.patientMedicalRequests?.totalCount || 0;

  const totalMedicalCount =
    newMedicalCount + forwardedMedicalCount + forApprovalMedicalCount;

  const PATIENT_INQUIRIES = gql`
    query {
      patientInquiries(unreadOnly: true) {
        totalCount
      }
    }
  `;

  const { data: patientInquiries = [] } = useQuery(PATIENT_INQUIRIES, {
    fetchPolicy: "network-only",
    pollInterval: 5 * 60 * 1000, //5mins
    onError: (err) => {},
  });

  const prescriptionRequestsCount =
    prescriptionRequests?.patientPrescriptionRequests?.totalCount || 0;

  const patientIniquiriesCount =
    patientInquiries?.patientInquiries?.totalCount || 0;

  const REQUEST_USER_PERMISSION = gql`
    query receiveDate {
      userPermissions @client
    }
  `;
  const { data: user_permissions = { user_permissions: [] } } = useQuery(
    REQUEST_USER_PERMISSION
  );

  let permissions = [
    "patients",
    "appointments",
    "my_appointments",
    "prescriptions",
    "waiting_list",
    "payments",
    "unpaid_payments",
    "settings",
    "inquiries",
  ];
  permissions = permissions.filter((i) =>
    getPermissionForAction(i, "list", user_permissions.userPermissions)
  );
  permissions.push("unpaid_payments");
  const has_payments_permission = getPermissionForAction(
    "payments",
    "add",
    user_permissions.userPermissions
  );
  const has_appointment_permissions = getPermissionForAction(
    "appointments",
    "add",
    user_permissions.userPermissions
  );

  const has_inquiry_permissions = getPermissionForAction(
    "inquiries",
    "add",
    user_permissions.userPermissions
  );

  const has_medical_permission = getPermissionForAction(
    "medical",
    "list",
    user_permissions.userPermissions
  );

  let navigation_links = [
    {
      key: "patients",
      slug: "/patients",
      icon: "fa fa-users",
      title: "Patients",
    },
    {
      key: "appointments",
      slug: "/appointments",
      icon: "fa fa-calendar",
      title: "Appointments",
    },
    {
      key: "my_appointments",
      slug: "/my/appointments",
      icon: "fa fa-calendar",
      title: "My Appointments",
    },
    {
      key: "waiting_list",
      slug: "/waiting/list",
      icon: "fa fa-edit",
      title: "Waiting List",
    },
    {
      key: "payments",
      slug: "/payments",
      icon: "fa fa-credit-card",
      title: "Payments",
    },
    {
      key: "unpaid_payments",
      slug: "/pending/payments",
      icon: "fa fa-credit-card",
      title: "Unpaid manual payments",
    },
    // {
    //   key: 'settings',
    //   slug: '/settings',
    //   icon: 'fa fa-cog',
    //   title: 'Settings',
    // },
  ];
  const toggleNav = (open) => {
    if (open) {
      document.getElementById("mySidenav").style.width = "250px";
      document.getElementById("nav_overlay").classList.remove("d-none");
    } else {
      document.getElementById("mySidenav").style.width = "0";
      document.getElementById("nav_overlay").classList.add("d-none");
    }
  };

  const renderNavContents = () => {
    return (
      <>
        <div>
          <div className="d-md-none userdetails px-4 mb-4">
            <h6 className="textPrimary">Logged in as:</h6>
            <span className="textPrimary capitalize wrapAnywhere">
              {userDetails ? userDetails.username : "User"}
            </span>
          </div>
          <Link to={"/dashboard"}>
            {" "}
            <Button className="text-left" variant="link">
              {" "}
              <i className={" fa fa-columns mx-2 "} aria-hidden="true"></i>{" "}
              Dashboard
            </Button>
          </Link>
          {navigation_links.map((link, index) => {
            if (permissions.includes(link.key)) {
              return (
                <Link to={link.slug} key={index}>
                  {" "}
                  <Button className="text-left" variant="link">
                    {" "}
                    <i
                      className={link.icon + " mx-2 "}
                      aria-hidden="true"
                    ></i>{" "}
                    {link.title}
                  </Button>
                </Link>
              );
            } else {
              return null;
            }
          })}
          {has_appointment_permissions ? (
            <Dropdown>
              <Dropdown.Toggle variant="link" id="dropdown-basic">
                <i className="fa fa-receipt mx-2" aria-hidden="true"></i>{" "}
                Prescriptions{" "}
                {prescriptionRequestsCount > 0 && (
                  <Badge variant="success">{prescriptionRequestsCount}</Badge>
                )}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="/prescription/print">
                  Create new prescription
                </Dropdown.Item>
                <Dropdown.Item href="/requests/prescriptions">
                  Prescription request{" "}
                  {prescriptionRequestsCount > 0 && (
                    <Badge variant="success">{prescriptionRequestsCount}</Badge>
                  )}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : null}
          {has_payments_permission ? (
            <Link to="/manual/payment">
              <Button variant="link" className="text-left">
                <i className="fa fa-receipt mx-2" aria-hidden="true"></i> Manual
                Payment
              </Button>
            </Link>
          ) : null}
          {has_inquiry_permissions ? (
            <Link to="/requests/inquiries">
              <Button variant="link" className="text-left">
                <i className="fa fa-receipt mx-2" aria-hidden="true"></i>{" "}
                Inquiries{" "}
                {patientIniquiriesCount > 0 && (
                  <Badge variant="success">{patientIniquiriesCount}</Badge>
                )}
              </Button>
            </Link>
          ) : null}

          {/* {has_medical_permission ? (
            <Dropdown>
              <Dropdown.Toggle variant="link" id="dropdown-basic">
                <i className="fa fa-receipt mx-2" aria-hidden="true"></i>{" "}
                Medical report{" "}
                {totalMedicalCount > 0 && (
                  <Badge variant="success">{totalMedicalCount}</Badge>
                )}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="/requests/medical">
                  New{" "}
                  {newMedicalCount > 0 && (
                    <Badge variant="success">{newMedicalCount}</Badge>
                  )}
                </Dropdown.Item>
                <Dropdown.Item href="/requests/medical/forapproval">
                  Waiting for approval{" "}
                  {forApprovalMedicalCount > 0 && (
                    <Badge variant="success">{forApprovalMedicalCount}</Badge>
                  )}
                </Dropdown.Item>
                <Dropdown.Item href="/requests/medical/forwardedtome">
                  Forwarded to me{" "}
                  {forwardedMedicalCount > 0 && (
                    <Badge variant="success">{forwardedMedicalCount}</Badge>
                  )}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : null} */}

          <LogoutButton />
        </div>
      </>
    );
  };
  return (
    <>
      <div className="mobile_nav w100 justify-content-between align-items-center">
        <span onClick={() => toggleNav(true)} className="flex1">
          <i className={"fa fa-bars menu_icon "} aria-hidden="true"></i>{" "}
        </span>
        <Link to="/dashboard" className="d-flex justify-content-center flex1">
          <img alt={"Logo"} className="img-fluid navLogo" src={Logo} />
        </Link>
        <h6 className="today_date text-right mb-0 flex1">{getDate()}</h6>
        <div
          id="nav_overlay"
          onClick={() => toggleNav()}
          className=" d-none"
        ></div>
      </div>
      <Row className="flex4 user_details">
        <Col>
          <h6 className="today_date mb-5 mt-3 text-right">{getDate()}</h6>
          <Link to="/dashboard">
            <img alt={"Logo"} className="img-fluid navLogo" src={Logo} />
          </Link>
          <h6 className="today_date text-center mt-4 "> Logged in as </h6>
          <span className="text-light wrapAnywhere">
            {userDetails ? userDetails.username : "User"}
          </span>
        </Col>
      </Row>
      <Row className="flex6 tabs">{renderNavContents()}</Row>
      <div id="mySidenav" className="sidenav">
        <div className="closebtn" onClick={() => toggleNav()}>
          <i className={"fa fa-times "} aria-hidden="true"></i>{" "}
        </div>
        {renderNavContents()}
      </div>
    </>
  );
};

export default withRouter(Navigation);
